import "./App.css"
import video from "./video.mov"

function App() {
	return (
		<>
			<div className="App">
				<div className="video-background">
					<h1>
						roha.net
						<br />
						<span style={{ fontSize: "12px" }}>Wartungsmodus aktiv...</span>
						<br />
						<br />
						<span style={{ fontSize: "08px" }}> code: TB (http://tb-net.de/)</span>
					</h1>
				</div>
			</div>

			<video autoPlay muted loop playsinline webkit-playsinline id="video-background">
				<source src={video} type="video/webm" />
			</video>
		</>
	)
}

export default App
